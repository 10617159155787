import Header from '@components/UI/Header/Header'
import { useState, useEffect } from 'react'

import { newsDto } from '@interfaces/dto/news.dto'
import { REQ } from '@api/index'

import PromotionsSlider from '@components/componentsHome/PromotionsSlider/PromotionsSlider'

import styles from './CurrentPromotions.module.sass'
import { toast } from 'react-toastify'

interface IProps {
  entity?: 'passengerTransportation' | 'cargoTransportation' | ''
}

const CurrentPromotions = ({ entity = '' }: IProps) => {
  const { news_api } = REQ

  const [discounts, setDiscounts] = useState<newsDto[]>([])
  const [isDiscountsLoading, setDiscountsLoading] = useState<boolean>(false)

  useEffect(() => {
    setDiscountsLoading(true)
    news_api
      .getNews(
        {
          page: 1,
          count: 6,
        },
        {
          type: 'discount',
          language: 'UA',
          entity,
        },
      )
      .then((res) => {
        setDiscountsLoading(false)
        setDiscounts(res?.data?.data)
      })
      .catch((err) => {
        setDiscountsLoading(false)
        console.log(err)
        toast.error('Помилка завантаження акцій')
      })
  }, [])

  console.log("discounts", discounts)

  return (
    <div className={styles.container} id='promotions'>
      <div className={styles.topContainer}>
        <div className={styles.headerContainer}>
          <Header color='blueYellow'>
            Актуальні <span>промоакції</span> та <span>знижки</span>
          </Header>
        </div>

        <div className={styles.descriptionContainer}>
          <p>
            Ми забезпечуємо широкий вибір маршрутів, що охоплюють найбільш
            популярні напрямки між країнами Європи.
          </p>
        </div>
      </div>

      {discounts?.length > 0 && (
        <div className={styles.newsContainer}>
          <PromotionsSlider data={discounts} />
        </div>
      )}
    </div>
  )
}

export default CurrentPromotions
