import { ReactSVG } from 'react-svg'

import styles from './arrows.module.sass'

interface IProps {
  onClick?: () => void
}

const PrevArrow = ({ onClick }: IProps) => {
  return (
    <button
      style={{
        left: '-50px',
      }}
      className={styles.arrow}
      type="button"
      onClick={onClick}>
      <ReactSVG src="/icons/prev-arrow.svg" />
    </button>
  )
}

export default PrevArrow
