import styles from './TextTwoColumns.module.sass'

interface IProps {
  data: {
    id: number
    textContent: JSX.Element
  }[]
}

const TextTwoColumns = ({ data }: IProps) => {
  return (
    <div className={styles.textTwoColumns}>
      <div className={styles.column}>
        {data.map(
          (item) =>
            item.id % 2 === 0 && (
              <div key={item.id} className={styles.paragraphContainer}>
                {item.textContent}
              </div>
            )
        )}
      </div>

      <div className={styles.column}>
        {data.map(
          (item) =>
            item.id % 2 !== 0 && (
              <div key={item.id} className={styles.paragraphContainer}>
                {item.textContent}
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default TextTwoColumns
